import Advantage1 from '../../../images/private/advantage1.png'
import Advantage2 from '../../../images/private/advantage2.png'
import Advantage3 from '../../../images/private/advantage3.png'
import Advantage4 from '../../../images/private/advantage4.png'
import Advantage5 from '../../../images/private/advantage5.png'
import Find1 from '../../../images/private/find1.png'
import Find2 from '../../../images/private/find2.png'
import Find3 from '../../../images/private/find3.png'
import Find4 from '../../../images/private/find4.png'
import Find5 from '../../../images/private/find5.png'
import Find6 from '../../../images/private/find6.png'
import Find7 from '../../../images/private/find7.png'
import Find8 from '../../../images/private/find8.png'
import Service1 from '../../../images/private/service1.png'
import Service2 from '../../../images/private/service2.png'
import Service3 from '../../../images/private/service3.png'
import Service4 from '../../../images/private/service4.png'

export const advantageList = [
  {
    title: '数据安全私密',
    desc: '通过AES-256加密、双因子认证等措施，严防非法访问与数据泄露',
    iconUrl: Advantage1,
  },
  {
    title: '企业自主掌控',
    desc: '进行成员、设备、权限等定制化管理配置，满足不同岗位需求，提高工作效率',
    iconUrl: Advantage2,
  },
  {
    title: '高可用方案设计',
    desc: '定制全平台界面风格、功能配置、接入方式等，方案精准满足企业需求',
    iconUrl: Advantage3,
  },
  {
    title: '轻量级部署',
    desc: '采用轻量级部署模式，方案具备高兼容性，简化部署流程，提升部署效率',
    iconUrl: Advantage4,
  },
  {
    title: '专属服务支持',
    desc: '专业技术支持团队为您提供针对性的服务，专家参与全流程支持',
    iconUrl: Advantage5,
  },
]

export const findList = [
  {
    title: '成员管理',
    desc: '自主添加、删除、编辑成员信息，灵活管理与控制成员',
    iconUrl: Find1,
  },
  {
    title: '设备管理',
    desc: '集中管理和监控企业设备，确保设备的稳定性和正常运行',
    iconUrl: Find2,
  },
  {
    title: '角色权限',
    desc: '根据岗位设置权限级别，使员工只获取其需要且必要的信息和功能',
    iconUrl: Find3,
  },
  {
    title: '权限策略',
    desc: '制定细致的权限控制策略，包括功能限制、访问控制等，确保权责分明',
    iconUrl: Find4,
  },
  {
    title: '文件传输',
    desc: '搭载自研传输协议，实现快速、安全、跨端文件传输，提高协作效率',
    iconUrl: Find5,
  },
  {
    title: '软件定制',
    desc: '根据需求个性化定制功能与界面，增强系统的可用性和适应性',
    iconUrl: Find6,
  },
  {
    title: '批量部署',
    desc: '通过MSI静默安装或AD域批量执行，高效、统一地部署软件和配置',
    iconUrl: Find7,
  },
  {
    title: '安全审计',
    desc: '实时监测操作与事件，生成日志记录，防范风险，追踪问题',
    iconUrl: Find8,
  },
]

export const serviceList = [
  {
    title: '需求沟通',
    sIndex: '01',
    desc: '了解企业工作模式，按需定制专属管理与远控解决方案',
    iconUrl: Service1,
  },
  {
    title: '确定方案',
    sIndex: '02',
    desc: '进行专业技术咨询与沟通，确定私有化部署方案',
    iconUrl: Service2,
  },
  {
    title: '高效部署',
    sIndex: '03',
    desc: '专家团队全程负责，进行测试、调试，完成高质量交付',
    iconUrl: Service3,
  },
  {
    title: '售后服务',
    sIndex: '04',
    desc: '提供专属技术支持与指导，售后服务快速响应',
    iconUrl: Service4,
    lastOne: true,
  },
]

export const deployList = [
  {
    title: '部署服务',
    dList: ['定制化开发', '针对性解决方案', '对接内部系统，跨系统融合'],
  },
  {
    title: '成员管理',
    dList: ['自定义组织架构', 'AD域导入', '用户组远控权限策略'],
  },
  {
    title: '设备管理',
    dList: [
      '批量部署设备',
      '设备授权管理',
      '主控设备管理',
      '受控设备管理',
      '设备组权限策略',
    ],
  },
  {
    title: '权限安全',
    dList: [
      '企业级性能架构及管理平台',
      '用户角色与访问管理',
      '双因子验证',
      '用户/设备分组管理',
      '管理员权限策略',
      '审查日志',
      '防窥模式',
      '水印',
      '传输方向限制',
      '加密录屏',
    ],
  },
]
