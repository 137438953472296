import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import DeployBg from '../../../../../images/private/deployBg.png'
import { Media } from '@raylink/common/theme'

export const DeployWrap = styled.div`
  min-width: 1200px;
  padding: 100px 0 80px;
  position: relative;
  background: linear-gradient(180deg, #ffffff 0%, #f2eaf8 50%, #ffffff 100%);
  .deploy-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 360px;
    background: url(${DeployBg}) no-repeat top center;
    background-size: 100% auto;
  }
  .title {
    margin-bottom: 6px;
    position: relative;
    z-index: 1;
  }
  .title-desc {
    font-size: 16px;
    color: #878787;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  ${Media.ipad`
    min-width: 944px;
    padding: 60px 0 80px;
  `}
  ${Media.phone`
    min-width: 100%;
    padding: 0.8rem 0.4rem 1.08rem;
    .deploy-bg {
      display: none;
    }
    .title {
      margin-bottom: 0.14rem;
    }
    .title-desc {
      font-size: 0.24rem;
    }
  `}
`

export const DeployList = styled(Warpper)`
  display: flex;
  gap: 18px;
  margin-top: 56px;
  position: relative;
  z-index: 1;
  ${Media.ipad`
    gap: 16px;
    margin-top: 36px;
  `}
  ${Media.phone`
    flex-direction: column;
    gap: 0.24rem;
    margin-top: 0.48rem;
  `}
`

export const DeployItem = styled.div`
  flex: 1;
  padding: 30px 30px 40px;
  border-radius: 10px;
  border: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 37.82%,
    rgba(255, 255, 255, 0.3) 100%
  );

  .title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    color: #1f2329;
  }
  .item-list-div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .item {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      color: #3e5060;
    }
  }
  ${Media.ipad`
    padding: 24px 20px 28px;
    .title {
      font-size: 18px;
    }
  `}
  ${Media.phone`
    padding: 0.4rem;
    border-radius: 0.24rem;
    .title {
      margin-bottom: 0.24rem;
      font-size: 0.4rem;
    }
    .item-list-div {
      gap: 0.18rem;
      .item {
        gap: 0.12rem;
        font-size: 0.24rem;
        img {
          width: 0.24rem;
        }
      }
    }
  `}
`
