import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import FindBg from '../../../../../images/private/findBg.png'
import FindMObileBg from '../../../../../images/private/findMobileBg.png'
import { Media } from '@raylink/common/theme'

export const FindWrap = styled.div`
  position: relative;
  width: 100%;
  min-width: 1200px;
  padding: 100px 0 112px;
  background: url(${FindBg}) no-repeat top center / 100%;
  ${Media.ipad`
    min-width: 944px;
    padding: 60px 0;
  `}
  ${Media.phone`
    min-width: 100%;
    padding: 0.8rem 0;
    background: url(${FindMObileBg}) no-repeat top center / 100%;
    background-size: 100% auto;
  `}
`
export const FindListWrap = styled(Warpper)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #fff;
  box-shadow: 0px 10px 20px 0px rgba(204, 204, 204, 0.2);
  ${Media.ipad`
    grid-template-columns: repeat(3, 1fr);
    border: none;
    box-shadow: none;
  `}
  ${Media.phone`
    display: flex;
    flex-direction: column;
    grid-template-columns: unset;
    padding: 0 0.4rem;
    border-radius: 0;
    box-shadow: unset;
  `}
`
export const FindItem = styled.div`
  padding: 30px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.5);
  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 50.82%,
      rgba(225, 228, 255, 0.5) 100%
    );
    .title {
      color: #445fff;
    }
  }
  img {
    width: 50px;
  }
  .title {
    margin: 6px 0;
    font-size: 20px;
    color: #212121;
    font-weight: 700;
  }
  .desc {
    font-size: 14px;
    color: #586978;
  }
  ${Media.phone`
    display: flex;
    justify-content: space-between;
    padding: 0.4rem;
    .right {
      width: 4.8rem;
    }
    img {
      width: 0.72rem;
      height: fit-content;
    }
    .title {
      margin: 0 0 0.09rem;
      font-size: 0.36rem;
    }
    .desc {
      font-size: 0.24rem;
    }
  `}
`
