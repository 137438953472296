import React from 'react'
import { findList } from '../../config'
import { Title } from '../../index.style'
import { FindWrap, FindListWrap, FindItem } from './atom'

export function Find({ ...rest }) {
  return (
    <FindWrap {...rest}>
      <Title>发现特色功能</Title>
      <FindListWrap>
        {findList.map(item => (
          <FindItem key={item.title}>
            <img src={item.iconUrl} alt="" loading="lazy" />
            <div className="right">
              <h3 className="title">{item.title}</h3>
              <div className="desc">{item.desc}</div>
            </div>
          </FindItem>
        ))}
      </FindListWrap>
    </FindWrap>
  )
}
