import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'

export const AdvantageWrap = styled.div`
  min-width: 1200px;
  padding: 100px 0;
  background: linear-gradient(180deg, #ffffff 0%, #f2eaf8 50%, #ffffff 100%);
  .ad-title {
    margin-bottom: 66px;
  }
  ${Media.ipad`
    min-width: 944px;
    padding: 60px 0 72px;
    .ad-title {
      margin-bottom: 46px;
    }
  `}
  ${Media.phone`
    min-width: 100%;
    padding: 0.8rem 0 0.9rem;
    .ad-title {
      margin-bottom: 0.54rem;
    }
  `}
`

export const AdvantageListWrap = styled(Warpper)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  ${Media.ipad`
    gap: 20px;
  `}
  ${Media.phone`
    gap: 0.4rem;
    padding: 0 0.35rem;
  `}
`

export const AdvantageItem = styled.div`
  width: 360px;
  padding: 30px 20px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ffffff;
  img {
    width: 64px;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 20px;
    margin: 14px auto 8px;
    color: #212121;
    font-weight: 700;
    line-height: 1.5;
  }
  .desc {
    font-size: 14px;
    line-height: 1.5;
    color: #586571;
    text-align: center;
  }
  ${Media.ipad`
    width: 300px;
    .title {
      margin: 20px auto 8px;
    }
  `}
  ${Media.phone`
    width: 100%;
    padding: 0.4rem;
    border-radius: 0.12rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    img {
      width: 0.96rem;
    }
    .right {
      width: 4.8rem;
      align-items: flex-start;
    }
    .title {
      font-size: 0.36rem;
      margin: 0 0 0.12rem;
    }
    .desc {
      font-size: 0.24rem;
      text-align: left;
    }
  `}
`
