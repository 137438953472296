import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const ConsultWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  ${Media.ipad`
    gap: 14px;
  `}
  ${Media.phone`
    gap: 0.4rem;
  `}
`
export const ContactBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding: 0 40px;
  height: 40px;
  text-align: center;
  border-radius: 6px;
  background: #445fff;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 10px 20px 0px rgba(68, 95, 255, 0.3);
  }
  ${Media.phone`
    font-size: 0.24rem;
    padding: 0 0.6rem;
    height: 0.66rem;
    border-radius: 0.09rem;
  `}
`
export const QrWrap = styled.div`
  position: relative;
  .qr-img {
    width: 80px;
    height: 80px;
  }
  .hover-qr-div {
    position: absolute;
    top: -100000px;
    left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;
    border-radius: 6px;
    font-size: 12px;
    text-align: center;
    color: #333;
    background: #fff;
    border: 1px solid rgba(255, 255, 255, 0.8);
    box-shadow: 0px 10px 20px 0px rgba(112, 197, 244, 0.3);
    z-index: 1;
    .obtain {
      color: #878787;
    }
    > img {
      width: 118px;
    }
  }
  &:hover {
    .hover-qr-div {
      top: -60px;
    }
  }
  ${Media.phone`
    .qr-img {
      width: 1.2rem;
      height: 1.2rem;
    }
    .hover-qr-div {
      left: 1.4rem;
      padding: 0.16rem 0;
      border-radius: 0.12rem;
      font-size: 0.24rem;
      box-shadow: 0px 0.2rem 0.4rem 0px rgba(112, 197, 244, 0.3);
      > img {
        width: 2.3rem;
      }
    }
    &:hover {
      .hover-qr-div {
        top: -0.6rem;
      }
    }
  `}
`
