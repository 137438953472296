import React from 'react'
import { Consult } from '../consult'
import { PrivateBannerWrap, PrivateBannerContent } from './atom'

export function PrivateBanner({ ...rest }) {
  return (
    <PrivateBannerWrap {...rest}>
      <PrivateBannerContent>
        <h1 className="title">RayLink远控私有化部署</h1>
        <div className="desc">
          提供安全、精准、轻量级的私有化部署方案，助力企业实现自主管理与控制，构建高效、可靠的企业级远控服务体系
        </div>
        <Consult />
        <div className="phone">电话咨询：400 - 965 - 5018 转 3</div>
      </PrivateBannerContent>
    </PrivateBannerWrap>
  )
}
