import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'

export const ServiceWrap = styled.div`
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 54px;
  background: linear-gradient(
    180deg,
    rgba(112, 197, 244, 0.3) 0%,
    #e2ffff 100%
  );
  ${Media.ipad`
    min-width: 944px;
    padding: 60px 0 20px;
  `}
  ${Media.phone`
    min-width: 100%;
    padding: 0.8rem 0 1.12rem;
    .service-consult {
      .hover-qr-div {
        left: -2.5rem;
      }
    }
  `}
`

export const ServiceListWrap = styled(Warpper)`
  display: flex;
  align-items: center;
  margin-bottom: 42px;
  .next-img {
    width: 30px;
    margin: 0 10px;
  }
  ${Media.ipad`
    margin-bottom: 38px;
  `}
  ${Media.phone`
    flex-direction: column;
    padding: 0 0.4rem;
    margin-bottom: 0.8rem;
    .next-img {
      transform: rotate(90deg);
      width: 0.6rem;
      margin: 0 0.04rem;
    }
  `}
`

export const ServiceItem = styled.div`
  flex: 1;
  padding: 30px 20px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 37.82%,
    rgba(255, 255, 255, 0.3) 100%
  );
  img {
    width: 48px;
  }
  .title {
    margin: 12px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    span {
      color: #4462ff;
      margin-right: 6px;
    }
  }
  .desc {
    font-size: 16px;
    color: #586571;
  }
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 37.82%,
    rgba(255, 255, 255, 0.3) 100%
  );
  border: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(112, 197, 244, 0.2) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  ${Media.phone`
    padding: 0.4rem;
    border-radius: 0.2rem;
    display: flex;
    justify-content: space-between;
    gap: 0.24rem;
    .right {
      width: 4.4rem;
    }
    img {
      width: 0.96rem;
      height: fit-content;
    }
    .title {
      margin: 0;
      font-size: 0.36rem;
      span {
        margin-right: 0.12rem;
      }
    }
    .desc {
      font-size: 0.28rem;
    }
  `}
`
