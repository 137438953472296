import React from 'react'
import { Layout, SEO } from '@raylink/common/components'
import {
  PrivateBanner,
  Advantage,
  Find,
  Service,
  Deploy,
  Professional,
} from './component'
import { PrivateWrap } from './index.style'

export default function PrivatePage() {
  return (
    <Layout>
      <SEO
        title="RayLink远程控制私有化部署服务"
        keywords="RayLink,私有化部署,远程设备管理,批量部署,远程运维,远程管理"
        description="为IT管理者和运维人员提供安全、合规的解决方案，覆盖双因子认证、角色权限、数据安全、审计日志等多个层次的安全需求，为企业构建完善的远控安全体系。"
        ogUrl="product/privatization/"
      />
      <PrivateWrap>
        <PrivateBanner />
        <Advantage />
        <Find />
        <Service />
        <Deploy />
        <Professional />
      </PrivateWrap>
    </Layout>
  )
}
