import React from 'react'
import { advantageList } from '../../config'
import { Title } from '../../index.style'
import { AdvantageWrap, AdvantageListWrap, AdvantageItem } from './atom'

export function Advantage({ ...rest }) {
  return (
    <AdvantageWrap {...rest}>
      <Title className="ad-title">RayLink私有化部署优势</Title>
      <AdvantageListWrap>
        {advantageList.map(item => (
          <AdvantageItem key={item.title}>
            <img src={item.iconUrl} alt="" loading="lazy" />
            <div className="right">
              <h3 className="title">{item.title}</h3>
              <div className="desc">{item.desc}</div>
            </div>
          </AdvantageItem>
        ))}
      </AdvantageListWrap>
    </AdvantageWrap>
  )
}
