import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const ProfessionalWrap = styled.div`
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 40px;
  color: #fff;
  text-align: center;
  background: radial-gradient(
    100% 711.11% at 100% 13.51%,
    #55bcee 0%,
    #445fff 100%
  );
  .white-logo {
    width: 190px;
  }
  .p-title {
    margin: 40px 0 8px;
    font-size: 48px;
    font-weight: 700;
  }
  .consult {
    a {
      background: #fff;
      color: #445fff;
      &:hover {
        box-shadow: 0px 10px 20px 0px rgba(255, 255, 255, 0.3);
      }
    }
  }
  .phone {
    margin-top: 16px;
    font-size: 16px;
  }
  ${Media.ipad`
    min-width: 944px;
    .p-title {
      margin: 20px 0 8px;
    }
  `}
  ${Media.phone`
    min-width: 100%;
    padding: 0.8rem 0.4rem 0.6rem;
    .white-logo {
      width: 2.84rem;
    }
    .p-title {
      margin: 0.2rem 0 0.48rem;
      font-size: 0.28rem;
    }
    .consult {
      .hover-qr-div {
        left: -2.5rem;
      }
    }
    .phone {
      margin-top: 0.44rem;
      font-size: 0.24rem;
    }
  `}
`
