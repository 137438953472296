import React from 'react'
import CheckIcon from '../../../../../images/check-full.svg'
import { deployList } from '../../config'
import { Title } from '../../index.style'
import { DeployWrap, DeployList, DeployItem } from './atom'

export function Deploy({ ...rest }) {
  return (
    <DeployWrap {...rest}>
      <div className="deploy-bg" />
      <Title className="title">私有化部署专享服务权益</Title>
      <div className="title-desc">
        致力于提供行业领先的企业级远控服务，最大程度满足客户的个性化定制需求
      </div>
      <DeployList>
        {deployList.map(item => (
          <DeployItem key={item.title}>
            <div className="title">{item.title}</div>
            <div className="item-list-div">
              {item.dList.map(i => (
                <div className="item" key={i}>
                  <img src={CheckIcon} alt="" loading="lazy" />
                  {i}
                </div>
              ))}
            </div>
          </DeployItem>
        ))}
      </DeployList>
    </DeployWrap>
  )
}
