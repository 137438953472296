import styled from 'styled-components'
import { Media } from '@raylink/common/theme'

export const PrivateWrap = styled.div`
  width: 100%;
`

export const Title = styled.h2`
  margin: 0 0 46px 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.5;
  color: #1f2329;
  text-align: center;
  ${Media.ipad`
    margin: 0 0 26px 0;
  `}
  ${Media.phone`
    margin: 0 0 0.52rem 0;
    font-size: 0.44rem;
  `}
`
