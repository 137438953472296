import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import Bg from '../../../../../images/private/bg.png'
import IpadBg from '../../../../../images/private/ipadBg.png'
import MobileBg from '../../../../../images/private/mobileBg.png'
import { Media } from '@raylink/common/theme'

export const PrivateBannerWrap = styled.div`
  position: relative;
  width: 100%;
  height: 680px;
  min-width: 1200px;
  background: url(${Bg}) center center / auto 100% no-repeat;
  ${Media.desktopContact`
    background-size: 100% auto;
  `}
  ${Media.ipad`
    height: 640px;
    min-width: 944px;
    background: url(${IpadBg}) center center / auto 100% no-repeat;
  `}
  ${Media.phone`
    background: url(${MobileBg}) center center / auto 100% no-repeat;
    background-size: 100% 100%;
    height: 12.4rem;
    min-width: 100%;
  `}
`
export const PrivateBannerContent = styled(Warpper)`
  padding-top: 172px;
  h1 {
    margin: 0;
    font-size: inherit;
  }
  .title {
    width: 330px;
    color: #212121;
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
  }
  .desc {
    width: 426px;
    margin: 16px 0 30px;
    font-size: 16px;
    color: #3e5060;
    line-height: 1.5;
  }
  .phone {
    margin-top: 24px;
    font-size: 16px;
    color: #878787;
  }
  ${Media.ipad`
    .desc {
      width: 310px;
      margin: 16px 0 26px;
    }
  `}
  ${Media.phone`
    padding: 1.8rem 0.4rem 0;
    .title {
      width: 4.96rem;
      font-size: 0.72rem;
      line-height: 0.9rem;
    }
    .desc {
      width: 6.52rem;
      margin: 0.24rem 0 0.1rem;
      font-size: 0.28rem;
    }
    .phone {
      margin-top: 0.36rem;
      font-size: 0.24rem;
    }
  `}
`
