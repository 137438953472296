import React from 'react'
import LogoImg from '../../../../../images/white-logo.svg'
import { Consult } from '../consult'
import { ProfessionalWrap } from './atom'

export function Professional({ ...rest }) {
  return (
    <ProfessionalWrap {...rest}>
      <img src={LogoImg} alt="" className="white-logo" loading="lazy" />
      <h1 className="p-title">专业提供安全、精准私有化方案</h1>
      <Consult className="consult" />
      <div className="phone">电话咨询：400 - 965 - 5018 转 3</div>
    </ProfessionalWrap>
  )
}
