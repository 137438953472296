import React from 'react'
import QR from '../../../../../images/private/QR.png'
import { ConsultWrap, QrWrap, ContactBtn } from './atom'

export function Consult({ ...rest }) {
  return (
    <ConsultWrap {...rest}>
      <ContactBtn href="/contact.html">立即咨询</ContactBtn>
      <QrWrap>
        <img src={QR} alt="" className="qr-img" loading="lazy" />
        <div className="hover-qr-div">
          <div>添加企业微信</div>
          <img src={QR} alt="" loading="lazy" />
          <div className="obtain">获取私有化服务</div>
          <div className="obtain">与报价方案</div>
        </div>
      </QrWrap>
    </ConsultWrap>
  )
}
