import React from 'react'
import ArrowImg from '../../../../../images/private/arrow.png'
import { serviceList } from '../../config'
import { Title } from '../../index.style'
import { Consult } from '../consult'
import { ServiceWrap, ServiceListWrap, ServiceItem } from './atom'

export function Service({ ...rest }) {
  return (
    <ServiceWrap {...rest}>
      <Title>为企业提供全周期服务</Title>
      <ServiceListWrap>
        {serviceList.map((item, index) => (
          <React.Fragment key={item.title}>
            <ServiceItem>
              <img src={item.iconUrl} alt="" loading="lazy" />
              <div className="right">
                <h3 className="title">
                  <span>{item.sIndex}</span>
                  {item.title}
                </h3>
                <div className="desc">{item.desc}</div>
              </div>
            </ServiceItem>
            {index < serviceList.length - 1 && (
              <img src={ArrowImg} alt="" className="next-img" loading="lazy" />
            )}
          </React.Fragment>
        ))}
      </ServiceListWrap>
      <Consult className="service-consult" />
    </ServiceWrap>
  )
}
